import { Fragment, Component, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import NotFound from "../components/base/NotFound.jsx";
import "../components/sadmin/sadmin.css";
import { io } from "socket.io-client";
import * as util from "../util.js";

export default function Sadmin() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = "Server Admin Utility";
  }, []);
  return <SadminPage user={user} setUser={setUser} />;
}

class SadminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      socket: false,
      appList: [],
    };
    this.initSocket = () => {
      const socket = io("https://speags.com", { path: "/apis/user/sadminws" });
      socket.once("connect", () => {
        socket.once("auth_req", () => {
          socket.emit("auth_res", this.state.user.jwt);
        });
        socket.once("auth_grant", () => {
          socket.on("pm2_list", (list) => {
            this.setState({ appList: list });
          });
          socket.on("pm2_fail", (err) => {
            console.log("Pm2 command failed!");
            console.log(err);
          });
        });
        socket.emit("ready");
      });
      return socket;
    };
  }
  componentDidUpdate(oldProps, oldState) {
    if (!!oldProps.user && !this.props.user)
      return this.setState({ user: false });
    if (
      oldProps.user !== this.props.user ||
      oldState.user !== this.state.user
    ) {
      let { user } = this.state;
      if (oldProps.user !== this.props.user && !!this.props.user)
        user = this.props.user;
      if (oldProps.user !== this.props.user && !this.props.user) user = false;
      if (!this.state.socket && !!this.state.user && this.state.user.owner) {
        return this.setState({ socket: this.initSocket() });
      }
      if (!!this.props.user && !this.state.user)
        return this.setState({ user: user });
    }
  }
  componentDidMount() {
    if (!!this.state.user && !!this.state.user.owner) {
      return this.setState({ socket: this.initSocket() });
    }
  }
  componentWillUnmount() {
    if (!!this.state.socket) {
      this.state.socket.close();
      return this.setState({ socket: false });
    }
  }
  render() {
    const { socket, appList, user } = this.state;
    let appJSX;
    if (!!appList && appList.length > 0)
      appJSX = (
        <Fragment>
          {appList.map((v) => {
            return (
              <div className="sPanelItem" key={v.pm_id}>
                <h3 className="sPanelItemTitle">{v.name}</h3>
                <h4 className="sPanelItemStatus">{`Status: ${v.pm2_env.status}`}</h4>
                <h4 className="sPanelItemUptime">{`Uptime: ${
                  v.pm2_env.status === "online"
                    ? util.msToTime(Date.now() - v.pm2_env.pm_uptime, true)
                    : "N/A"
                }`}</h4>
                <h5 className="sPanelItemResMem">{`RAM: ${(
                  v.monit.memory /
                  1024 /
                  1024
                ).toFixed(2)} MB`}</h5>
                <h5 className="sPanelItemResCpu">{`CPU: ${v.monit.cpu}%`}</h5>
                <button
                  className="startBtn"
                  disabled={v.pm2_env.status === "online"}
                  onClick={() => {
                    socket.emit("pm2_start", v.name);
                  }}
                >
                  Start
                </button>
                <button
                  className="restartBtn"
                  disabled={v.pm2_env.status !== "online"}
                  onClick={() => {
                    socket.emit("pm2_restart", v.name);
                  }}
                >
                  Restart
                </button>
                <button
                  className="stopBtn"
                  disabled={v.pm2_env.status !== "online"}
                  onClick={() => {
                    socket.emit("pm2_stop", v.name);
                  }}
                >
                  Stop
                </button>
              </div>
            );
          })}
        </Fragment>
      );
    return (
      <div className="SadminPanelContainer">
        {!!socket && !!appJSX && !!user && !!user.owner && (
          <Fragment>
            <div className="HardwarePanel"></div>
            <div className="ServerPanel">{appJSX}</div>
          </Fragment>
        )}
        {(!user || !user.owner) && <NotFound />}
      </div>
    );
  }
}
