import { Component } from "react";
import { Button, FigureImage } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { apiFetch, rawFetch } from "../../../util";
import { times } from "lodash";

class Channel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
    };
    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validate() {
    return this.state.title !== "" && this.state.description !== "";
  }

  handleSubmit(e) {
    e.preventDefault();
    const { user } = this.props;
    const channelData = {
      title: this.state.title,
      description: this.state.description,
    };
    if (!!this.props.editID) channelData.channelID = this.props.editID;
    const params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        jwt: user.jwt,
      },
      method: "POST",
      body: JSON.stringify(channelData),
    };
    apiFetch("blogchannel", params).then((res) => {
      user.jwt = res.jwt;
      if (res.status === 200) return this.props.closeEdit(res.data, user);
      else return console.log(res);
    });
  }

  render() {
    const { title, description } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group size="sm" controlId="blog-title">
          <Form.Label>Blog Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </Form.Group>
        <Form.Group size="sm" controlId="blog-description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
        </Form.Group>
        <Button size="sm" type="submit" disabled={!this.validate()}>
          {!!this.props.editID ? "Save" : "Submit"}
        </Button>
      </Form>
    );
  }
}

class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: "",
      components: [],
    };
    this.changeTimeout = false;
    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addComponent = this.addComponent.bind(this);
    this.removeComponent = this.removeComponent.bind(this);
  }

  validate() {
    let header = this.state.header !== "";
    let length = this.state.components.length > 0;
    let comps = true;
    this.state.components.forEach((c) => {
      if (c.content === "") comps = false;
    });
    console.log(header, length, comps);
    return header && length && comps;
  }

  handleSubmit() {
    const { header, components } = this.state;
    const entryData = {
      header: header,
      components: components,
      channel: this.props.channel,
    };
    if (!!this.props.editID) entryData.id = this.props.editID;
    const params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      body: JSON.stringify(entryData),
    };
    apiFetch("blogentry", params).then((res) => {
      const { user } = this.props;
      user.jwt = res.jwt;
      if (res.status === 200) return this.props.closeEdit(res.data, user);
      else return console.log(res);
    });
  }

  handleChange(index, value) {
    const set = () => {
      return setTimeout(() => {
        const { components } = this.state;
        components[index].content = value;
        this.setState({ components: components });
      }, 500);
    };
    if (!!this.changeTimeout) {
      clearTimeout(this.changeTimeout);
      this.changeTimeout = set();
    } else {
      this.changeTimeout = set();
    }
    return;
  }

  addComponent(type) {
    const { components } = this.state;
    components.push({ type: type, content: "" });
    return this.setState({ components: components });
  }

  removeComponent(index) {
    const { components } = this.state;
    components.splice(index, 1);
    return this.setState({ components: components });
  }

  render() {
    const { header, components } = this.state;
    const componentJSX =
      components.length > 0 ? (
        components.map((c, i) => {
          const remBtn = (
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return this.removeComponent(i);
              }}
            >
              X
            </button>
          );
          switch (c.type) {
            case "text": {
              return (
                <li className="entry_component" key={i}>
                  <Form.Group size="sm" controlId={`text_comp_${i}`}>
                    <Form.Label>Text Component</Form.Label>
                    {remBtn}
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={c.content}
                      onChange={(e) => {
                        e.preventDefault();
                        components[i].content = e.target.value;
                        this.setState({ components: components });
                      }}
                    />
                  </Form.Group>
                </li>
              );
            }
            case "media": {
              return (
                <li className="entry_component" key={i}>
                  <Form.Group size="sm" controlId={`img_comp_${i}`}>
                    <Form.Label>Image Component</Form.Label>
                    {remBtn}
                    <Form.Control
                      type="file"
                      multiple={true}
                      onChange={(e) => {
                        e.preventDefault();
                        components[i].content = Object.values(e.target.files);
                        return this.setState({ components: components });
                      }}
                    />
                  </Form.Group>
                </li>
              );
            }
          }
        })
      ) : (
        <div />
      );
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <Form.Group size="sm" controlId="entry_header">
          <Form.Label>Entry Header</Form.Label>
          <Form.Control
            type="text"
            value={header}
            onChange={(e) => {
              e.preventDefault();
              return this.setState({ header: e.target.value });
            }}
          />
        </Form.Group>
        <ul className="component_list">{componentJSX}</ul>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return this.addComponent("text");
          }}
        >
          Add text component
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return this.addComponent("media");
          }}
        >
          Add media component
        </button>
        <Button size="sm" type="submit" disabled={!this.validate()}>
          Submit
        </Button>
      </Form>
    );
  }
}

const BlogForm = {
  Channel,
  Entry,
};

export default BlogForm;
