import { Fragment, useEffect } from "react";
import AppTile from "../components/base/AppTile";
import { useOutletContext } from "react-router-dom";

export default function Landing() {
  const [user, setUser] = useOutletContext();
  const appTiles = require("./links.json").map((l) => {
    if (l.title === "Home") return null;
    if (l.title === "Server Admin" && (!user || !user.owner)) return null;
    else
      return (
        <AppTile
          key={l.title}
          link={l.href}
          imgSrc={l.imgSrc ? l.imgSrc : "logo192.png"}
          title={l.longTitle}
          description={l.description}
        />
      );
  });
  useEffect(() => {
    document.getElementById("title").innerText = "speags.com";
  }, []);
  return (
    <Fragment>
      <h1>Welcome to speags.com!</h1>
      <h2>This is the home for all things speags.</h2>
      <h2>
        From items for my resume, to links for my react apps, all the way to an
        update feed for my different hobby projects.
      </h2>
      <h2>This is where you'll find it all!</h2>
      <h2>Looking for one of my applications? Here's a list:</h2>
      <div className="App-list">{appTiles}</div>
      <h2>Looking to contact me? Reach me one of two ways:</h2>
      <h3>DM me on Discord @SpEaGs#2936</h3>
      <h3>email at z.speags@gmail.com</h3>{" "}
    </Fragment>
  );
}
