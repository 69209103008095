import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./routes/Root";
import VoidBotWebUI from "./routes/VoidBotWebUI";
import VoidCloud from "./routes/VoidCloud";
import Landing from "./routes/Landing";
import Profile from "./routes/UserProfile";
import Verify from "./routes/Verify";
import Blog from "./routes/Blog";
import Sadmin from "./routes/Sadmin";
import NotFound from "./components/base/NotFound";
import "./App.css";

import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <h1>Oops! Something went wrong!</h1>,
    children: [
      { index: true, element: <Landing /> },
      { path: "voidbot", element: <VoidBotWebUI /> },
      { path: "voidcloud", element: <VoidCloud /> },
      { path: "profile", element: <Profile /> },
      { path: "verify", element: <Verify /> },
      { path: "blog", element: <Blog /> },
      { path: "sadmin", element: <Sadmin /> },

      //catch all unrecognized paths
      { path: "*", element: <NotFound /> },
    ],
  },
  { path: "/vblogo192.png" },
  { path: "/githublogo192.png" },
  { path: "/robots.txt" },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
