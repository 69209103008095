const USER_API = "https://speags.com/apis/user/";

export function rawFetch(endpoint, params) {
  return new Promise((resolve, reject) => {
    fetch(`${USER_API}${endpoint}`, params).then((res) => {
      res.json().then((data) => {
        if (res.status === 200) {
          resolve(data);
        } else
          resolve({
            err: `Error fetching from API: ${data.message}`,
            code: res.status,
          });
      });
    });
  });
}

export function apiFetch(endpoint, params) {
  return new Promise((resolve, reject) => {
    let newJWT = "";
    fetch(`${USER_API}${endpoint}`, params)
      .then((res) => {
        if (res.status === 200) {
          newJWT = res.headers.get("jwt");
          res.json().then((data) => {
            if (!newJWT && !data) resolve(null);
            else
              resolve({
                jwt: newJWT ? newJWT : null,
                data: data ? data : null,
              });
          });
        } else {
          return res.json().then((data) => {
            resolve({
              err: `Error fetching from API: ${data.message}`,
              code: res.status,
            });
          });
        }
      })
      .catch((error) => {
        reject(`Unexpected Error when fetching from API: ${error}`);
      });
  });
}

export function download(fid, jwt) {
  return new Promise(async (resolve, reject) => {
    const DLParams = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "file-id": fid,
        jwt: jwt,
      },
      method: "GET",
    };
    const res = await fetch(`${USER_API}files`, DLParams);
    if ((res.code = 200)) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const contentDisposition = res.headers.get("Content-Disposition");
      const fileName = contentDisposition
        .split(";")
        .find((param) => param.trim().startsWith("filename="))
        .split("=")[1];
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      resolve(res.headers.get("jwt"));
    } else reject(new Error(`Response code ${res.code}`));
  });
}

function padNumber(num, length = 2) {
  return String(num).padStart(length, "0");
}

export function msToTime(ms, short = false) {
  let time = {
    secs: Math.floor((ms / 1000) % 60),
    mins: Math.floor((ms / (1000 * 60)) % 60),
    hours: Math.floor((ms / (1000 * 60 * 60)) % 24),
    days: Math.floor((ms / (1000 * 60 * 60 * 24)) % 365),
    years: Math.floor(ms / (1000 * 60 * 60 * 24 * 365)),
  };
  if (time.mins === 0 && time.hours === 0) time.mins = -1;
  if (time.hours === 0 && time.days === 0) time.hours = -1;
  if (time.days === 0 && time.years === 0) time.days = -1;
  let timeOut = [];

  if (time.mins <= 0 && time.hours <= 0 && time.days <= 0 && time.years <= 0)
    return short ? `${padNumber(time.secs)}` : `${time.secs} seconds`;
  else if (time.hours <= 0 && time.days <= 0 && time.years <= 0)
    return short
      ? `${padNumber(time.secs)}:${padNumber(time.mins)}`
      : `${time.mins} minutes, ${time.secs} seconds`;
  else if (time.days <= 0 && time.years <= 0)
    return short
      ? `${padNumber(time.hours)}:${padNumber(time.mins)}:${padNumber(
          time.secs
        )}`
      : `${time.hours} hours, ${time.mins} minutes, ${time.secs} seconds`;
  else {
    timeOut.unshift(short ? `${padNumber(time.secs)}` : `${time.secs} seconds`);
    if (time.hours >= 0)
      timeOut.unshift(
        short ? `${padNumber(time.mins)}:` : `${time.mins} minutes, `
      );
    if (time.hours >= 0)
      timeOut.unshift(
        short ? `${padNumber(time.hours)}:` : `${time.hours} hours, `
      );
    if (time.days >= 0)
      timeOut.unshift(
        short ? `${padNumber(time.days)}d, ` : `${time.days} days, `
      );
    if (time.years > 0)
      timeOut.unshift(
        short ? `${padNumber(time.years)}y, ` : `${time.years} years, `
      );
    return timeOut.join("");
  }
}
