import TitleBar from "../components/base/TitleBar";
import { Component } from "react";
import { Outlet, Navigate } from "react-router-dom";
import queryString from "query-string";
import MobileDetect from "mobile-detect";
import { apiFetch } from "../util";

export default class Root extends Component {
  constructor() {
    super();
    this.state = {
      user: false,
      os: new MobileDetect(window.navigator.userAgent).os(),
      redirect: false,
    };
    this.handleAuth = this.handleAuth.bind(this);
    this.setUser = this.setUser.bind(this);
  }

  componentDidMount() {
    const urlparams = queryString.parse(window.location.search);
    const dToken = urlparams.code ? urlparams.code : false;
    const urlState = urlparams.state ? JSON.parse(urlparams.state) : false;
    const receiveAuth = (result) => {
      if (result.err) {
        sessionStorage.removeItem("usrtkn");
        localStorage.removeItem("usrtkn");
        console.log(`Login failed (${result.code}):  ${result.err}`);
        return this.setState({ user: false });
      }
      const user = result.data;
      user.jwt = result.jwt;
      this.handleAuth("login", user, !!urlState.loc ? urlState.loc : null);
    };
    if (!this.state.user && !!dToken && !urlState.jwt) {
      const params = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify({
          authType: "dToken",
          dToken: dToken,
          keep: !!urlState.keep,
        }),
      };
      return apiFetch(`auth`, params).then(receiveAuth).catch(console.log);
    }
    const localToken = localStorage.getItem("usrtkn");
    if (!!localToken) sessionStorage.setItem("usrtkn", localToken);
    const token = sessionStorage.getItem("usrtkn");
    if (!this.state.user && !!token && !!urlState && !!urlState.jwt) {
      const params = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          jwt: urlState.jwt,
        },
        method: "POST",
        body: JSON.stringify({
          targets: ["dToken"],
          payload: { dToken: dToken },
        }),
      };
      return apiFetch("update", params).then(receiveAuth).catch(console.log);
    }
    if (!this.state.user && !!token) {
      const params = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          jwt: token,
        },
        method: "GET",
      };
      return apiFetch(`auth`, params).then(receiveAuth).catch(console.log);
    }
  }

  handleAuth(channel, data = null, loc = null) {
    switch (channel) {
      case "login": {
        if (!!data.jwt && !data.keep)
          sessionStorage.setItem("usrtkn", data.jwt);
        else if (!!data.jwt && data.keep) {
          sessionStorage.setItem("usrtkn", data.jwt);
          localStorage.setItem("usrtkn", data.jwt);
        }
        if (!!loc) {
          return this.setState({
            user: data,
            redirect: loc,
          });
        } else return this.setState({ user: data });
      }
      case "logout": {
        const token = sessionStorage.getItem("usrtkn");
        const params = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            jwt: token,
          },
          method: "GET",
        };
        apiFetch("logout", params)
          .then(() => {
            sessionStorage.removeItem("usrtkn");
            localStorage.removeItem("usrtkn");
            this.setState({ user: false });
          })
          .catch((err) => {
            console.log(
              new Error(
                "Error logging out user session. clearing client-side anyway..."
              ),
              err
            );
            sessionStorage.removeItem("usrtkn");
            localStorage.removeItem("usrtkn");
            this.setState({ user: false });
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  setUser(user) {
    if (!!user.jwt && !user.keep) sessionStorage.setItem("usrtkn", user.jwt);
    else if (!!user.jwt && user.keep) {
      sessionStorage.setItem("usrtkn", user.jwt);
      localStorage.setItem("usrtkn", user.jwt);
    }
    return this.setState({ user: user });
  }

  render() {
    const { user, os, redirect } = this.state;
    const mobile = !!(
      os === "iOS" ||
      os === "AndroidOS" ||
      os === "BlackBerryOS"
    );
    return (
      <div className="App">
        {!!redirect && <Navigate to={redirect} replace={true} />}
        <div className="App-header">
          <TitleBar
            mobile={mobile}
            user={user}
            title={mobile ? "Welcome!" : "Welcome to speags.com!"}
            doHandleAuth={this.handleAuth}
          />
        </div>
        <div className={`App-body ${mobile ? "mobile" : ""}`}>
          <Outlet context={[user, this.setUser]} />
        </div>
      </div>
    );
  }
}
